import React from "react"
import css from "@styled-system/css"

import { Box, Flex, Heading, ServicePill, Wrapper } from "components"

const ServicesSection = ({ data }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {data.services && (
        <Wrapper id="index-services">
          <Heading as="div" size={900} fontWeight="normal">
            {/* First line */}
            <Box
              as="p"
              m={0}
              pb={data.services && ["layout.3", null, null, "layout.4"]}
            >
              We offer the following services in {data.name}:
            </Box>

            {/* Services split */}
            {data.services && (
              <Flex
                flexWrap="wrap"
                m={["-2px", "-4px", null, "-8px"]}
                css={css({
                  "> *": {
                    p: ["2px", "4px", null, "8px"],
                  },
                })}
              >
                {data.services.map((service, index) => (
                  <ServicePill
                    image={service.image}
                    title={service.label}
                    color={service.color}
                    to={service.to}
                    key={index}
                  />
                ))}
              </Flex>
            )}
          </Heading>
        </Wrapper>
      )}
    </>
  )
}

export default ServicesSection
