import React from "react"

import { Box, Heading, RichText, Split } from "components"

const IntroSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.title || data.bodyContent || data.color) && (
        <Split id={id} image={data.image}>
          {/* Title */}
          {data.title && (
            <Heading size={900}>
              Service <br />
              <Box as="span" color={data.color}>
                {data.title}
              </Box>
            </Heading>
          )}

          {/* Content */}
          {data.bodyContent && (
            <RichText
              content={{
                html: data.bodyContent,
              }}
              size={500}
              mt={["layout.4", "layout.5", "layout.5", "layout.8"]}
            />
          )}
        </Split>
      )}
    </>
  )
}

export default IntroSection
