import React from "react"
import { graphql } from "gatsby"

import { SEO } from "components"

// Page sections
import IntroSection from "./_introSection"
import ServicesSection from "./_servicesSection"

const AreaTemplate = ({ data }) => (
  <>
    <SEO
      title={
        data.page.data.name && data.page.data.name.text + " | Where we operate"
      }
      description={
        data.page.data.description && data.page.data.description.text
      }
      image={data.page.data.image && data.page.data.image.url}
      imageAlt={data.page.data.image && data.page.data.image.alt}
    />

    {/* ============ INTRO ============ */}
    <IntroSection
      id="area-intro"
      data={{
        image: data.page.data.image,
        title: data.page.data.name && data.page.data.name.text,
        color: "brand.primary",
        bodyContent:
          data.page.data.description && data.page.data.description.html,
      }}
    />
    {data.page.data.services_offered && (
      <ServicesSection
        data={{
          name: data.page.data.name && data.page.data.name.text,
          services: data.page.data.services_offered.map(({ service }) => ({
            image: service.document && service.document.data.bin_image,
            label:
              service.document &&
              service.document.data.name &&
              service.document.data.name.text,
            color:
              service.document &&
              service.document.data.name &&
              service.document.data.color,
            to: `/services/${service.document && service.document.uid}/`,
          })),
        }}
      />
    )}
  </>
)

export const query = graphql`
  query AreaQuery($uid: String!) {
    page: prismicAreaOfOperation(uid: { eq: $uid }) {
      data {
        name {
          text
        }
        description {
          html
          text
        }
        image {
          url
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        services_offered {
          service {
            uid
            document {
              ... on PrismicService {
                uid
                data {
                  name {
                    text
                  }
                  color
                  bin_image {
                    fluid(maxWidth: 64) {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AreaTemplate
